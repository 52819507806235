import * as React from 'react';
import PropTypes from 'prop-types';
import { withSubtheme } from '../../../StarberryComponentsMui';
import useMediaQuery from "@mui/material/useMediaQuery";
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Actions from '../actions';
import { getCurrentTab } from "../helper"
import { useParams } from '@reach/router';
import { doRedirect, handleTabNavigation, removeLastSegment, stripTrailingSlash } from '../utils';

import defaults from './defaults';

// My Account
import clsx from "clsx"

function TabPanel(props) {
    const {
        children,
        value,
        index,
        tabPanel,
        tabPanelBox,
        className,
        altClassName
    } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`wrapped-tabpanel-${index}`}
            aria-labelledby={`wrapped-tab-${index}`}
            className={`${className} ${altClassName || ''}`}
            {...tabPanel}
        >
            {value === index && (
                <Box sx={tabPanelBox} className={"tabsMyAccount-tabPanelBox"}>
                    {children}
                </Box>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
};

function a11yProps(index) {
    return {
        id: `wrapped-tab-${index}`,
        'aria-controls': `wrapped-tabpanel-${index}`,
    };
}

const TabsWrappedLabel = withSubtheme(props => {
    const {
        className,
        altClassName,
        altTabClassName,
        fullWidthTabs,
        theme,

        tabsContainer,
        tabs,
        tab,
        tabPanel,
        tabPanelBox,
        data,
        _data,
        manageValue,
        tabItems,
        addUrl,
        tabLevel = 1,
        onlyTabContent=false
    } = props

    const urlParams = useParams()


    let tabsData = data ? data : _data;

    let initialValue = manageValue ? manageValue : "tab1";

    // if addUrl and tablists then change initialValue value to when it is getting re render
    if (tabItems && addUrl) {
        initialValue = getCurrentTab(initialValue, tabItems, urlParams)
    }

    console.log('urlParams =>', urlParams, addUrl, initialValue)

    const [value, setValue] = React.useState(initialValue);
    const handleChange = React.useCallback((event, initialValue) => {
        // if (!urlParams?.tab)
        setValue(initialValue);

        if (addUrl) {
            handleTabNavigation(initialValue, urlParams)
            // let url = `${window.location.pathname}#${initialValue}`;
            // if (urlParams?.tab) {
            //     url = `${removeLastSegment(stripTrailingSlash(window.location.pathname))}/${initialValue}`;
            //     doRedirect(url)
            // }
            // window.history.pushState({tab:initialValue}, '', url)
        }
    }, [addUrl]);

    const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

    // we can override the tabs variant if we pass it through props
    const tabProps = {
        ...tabs,
        ...isSmallScreen && fullWidthTabs && { variant: "fullWidth" }
    }

    return (
        <div className={`${className} ${altTabClassName}`} {...tabsContainer}>
            <AppBar position="static" color="transparent" elevation={0}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    className={onlyTabContent ? 'tab-hidden': ''}
                    classes={{
                        root: `tabsMyAccount-tabs tabLevel-${tabLevel}`,
                        indicator: "tabsMyAccount-indicator",
                        scrollButtons: "tabsMyAccount-scrollButtons"
                    }}
                    {...tabProps}
                >
                    {tabsData.map(tabData => {
                        return(
                            <Tab
                                key={tabData.id}
                                value={tabData.id}
                                label={tabData.name}
                                classes={{
                                    root: clsx("tabsMyAccount-tab", "tabsMyAccount-wrapper"),
                                    selected: "tabsMyAccount-selected",
                                }}
                                {...a11yProps(`${tabData.id}`)}
                                {...tab}
                                onClick={tabData?.customOnClick}
                            />
                        )
                    })}
                </Tabs>
            </AppBar>
            {tabsData.map(tab =>
                <TabPanel
                    key={tab.id}
                    value={value}
                    index={tab.id}
                    className={`tabsMyAccount-tabPanel ${altClassName}`}
                    tabPanelBox={tabPanelBox}
                    {...tabPanel}
                >
                    {tab.content ? tab.content : <Actions />}
                </TabPanel>
            )}
        </div>
    )
}, 'tabsMyAccount', defaults)

export default TabsWrappedLabel
