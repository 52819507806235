import * as React from 'react';
import { withSubtheme } from '../../../StarberryComponentsMui';
import { Typography, Box } from '@mui/material';

import defaults from './defaults';
import { styled } from '@mui/system';

const BoxComp= styled(Box)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        display: "none"
    }
}));

const PageTitle = withSubtheme(props => {
    const {
        className,
        children,
        pageTitleProps
    } = props

    return(
        <BoxComp>
            <Typography classes={{root: className}} {...pageTitleProps}>
                {children}
            </Typography>
        </BoxComp>
        
    )
}, 'globalPageTitle', defaults)

export default PageTitle;
