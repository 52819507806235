export default {
    "props": {
        "tabLevel": 1,
        "tabsContainer": {},
        "tabs": {
            "textColor": "primary",
            "aria-label": "tabs",
            "variant": "scrollable",
            "scrollButtons": "auto",
            "indicatorColor": "primary",
        },
        "tab": {
            "disableRipple": true,
        },
        "tabPanel": {},
        "tabPanelBox": {},
        "_tabData": [
            {
                "id": "tab1",
                "name": "Pending Actions",
            },
            {
                "id": "tab2",
                "name": "Recent Activity",
            }
        ],
    },
    "sx": (theme) => ({
        "flexGrow": 1,
        "marginBottom": theme.moduleSpacing,
        "&.pd-main": {
            [theme.breakpoints.down('md')]: {
                "& .tabLevel-1": {
                    "display": "none",
                },
                "& #wrapped-tabpanel-overview > .tabsMyAccount-tabPanelBox": {
                    "paddingTop": 0
                }
            }
        },
        [theme.breakpoints.up('sm')]: {
            "marginBottom": theme.moduleSpacingSm,
        },
        [theme.breakpoints.up('lg')]: {
            "marginBottom": theme.moduleSpacingLg,
        },
        // "backgroundColor": theme.palette.background.paper,
        "& .tabsMyAccount-tabs": {
            // "borderBottom": "1px solid #d8d8d8",
            "marginLeft": `-${theme.gridItemPmobile}`,
            "marginRight": `-${theme.gridItemPmobile}`,
            "& .MuiTabs-scroller": {
                "borderBottom": "1px solid #EAEAEA",
            },
            [theme.breakpoints.up('sm')]: {
                "marginLeft": `-${theme.gridItemPtablet}`,
                "marginRight": `-${theme.gridItemPtablet}`,
                "paddingLeft": theme.gridItemPtablet,
                "paddingRight": theme.gridItemPtablet,
            },
            [theme.breakpoints.up('lg')]: {
                "marginLeft": `calc(-${theme.gridItemPdesktop} * 2)`,
                "marginRight": `calc(-${theme.gridItemPdesktop} * 2)`,
                "paddingLeft": `calc(${theme.gridItemPdesktop} * 2)`,
                "paddingRight": `calc(${theme.gridItemPdesktop} * 2)`,
            },
            "& .tabsMyAccount-indicator": {
                "height": "3px",
                ...( theme?.palette?.tabs?.selected && {
                    "backgroundColor": theme.palette.tabs.selected
                }),
            },
            "& .tabsMyAccount-scrollButtons": {
                "alignItems": "flex-start",
                "& svg": {
                    "marginTop": "12px",
                },
                "&.Mui-disabled": {
                    "opacity": 0.3,
                },
            },
            "&.tabLevel-2": {
                // "borderBottom": 0,
                "& .tabsMyAccount-tab":{
                    // "fontSize": theme.bodySmall,
                    // [theme.breakpoints.up('sm')]: {
                    //     "fontSize": theme?.tabSecondLevel || theme?.bodyLarge,
                    // }
                }
            }
        },
        "& .tabsMyAccount-tab": {
            "fontFamily": theme?.typography?.tabs?.fontFamily || theme.secondaryFontFamily,
            "textTransform": "none",
            ...( theme?.palette?.tabs?.primary && {
                "color": theme.palette.tabs.primary
            }),
            "fontWeight": theme.palette.tabs?.fontWeight || '400',
            [theme.breakpoints.up('sm')]: {
                "minWidth": "auto",
                "paddingLeft": 0,
                "paddingRight": 0,
                "marginRight": theme.spacing(5),
            },
            [theme.breakpoints.up('md')]: {
                "paddingBottom":  theme.spacing(3.5),
            },
            "&.tabsMyAccount-selected": {
                "fontWeight": theme.typography.fontWeightBold,
                "color": theme?.palette?.tabs?.selected || theme.palette.secondary.main,
                "fontFamily": theme?.typography?.tabs?.fontFamilySelected || theme.secondaryFontFamily,
            },
            "&.tabsMyAccount-wrapper": {
                "fontSize": theme.bodySmall,
                [theme.breakpoints.up('sm')]: {
                    "fontSize": theme.bodyLarge,
                }
            },
        },
        "& .tabsMyAccount-tabPanel": {
            "padding": 0,
            "&.profile-tabs": {
                // "& .MuiCollapse-wrapper": {
                //     "paddingBottom": theme.spacing(2)
                // }
            }
        },
        "& .tabsMyAccount-tabPanelBox": {
            "padding": theme.spacing(2.75,0,0,0),
            [theme.breakpoints.up('md')]: {
                "padding": theme.spacing(4,0,0,0),
            },
        },
        "& .tab-hidden": {
            "display": "none"
        }
    }),
}
